export const theme = {
  colors: {
    primary: '#177EAA',
    highlight: '#2e8ab2',
    shade: '#126488',
    lightGrey: '#edf1f3', // e.g. Blobs
    darkGrey: '#626262', // e.g. primary text
    white: 'whitesmoke'
  },
  fonts: {
    primary: { // Headings
      family: 'Poppins', // sans-serif;
      weight: ['700']
    },
    secondary: { // Body text
      family: 'Roboto',
      weight: ['400', '500']
    }
  }
}
