import React from 'react'
import styled from 'styled-components'
// import { theme } from '../../theme'
import { media } from '../../helpers/media'

// export const BottomBlob = (
//   {/* Background SVG */}
//   <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 960 900'>
//     <defs>
//       <path id='a' d='M0 24h960v876H0z' />
//     </defs>
//     <clipPath id='b'>
//       <use xlinkHref='#a' overflow='visible' />
//     </clipPath>
//     <path clip-path='url(#b)' fill='#177EAA' d='M914.804 900C960 544 1036.543 75.274 700.088 39.567 376.226 5.197 364.103 179.419 186.585 77.493 83.504 18.307 0 24.16 0 24.16V900h914.804z' />
//   </svg>
// )

// Made using https://svgr.now.sh/
export const TopBlob = props => (
  <svg
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 1280 480'
    {...props}>
    <path fill='#177EAA' d='M26.678,1c-55.373,135.546-3.799,295.375,282.837,363.407 C476.694,404.085,585.643,268,983.244,428.055C1298.143,502.5,1266.143,1,1266.143,1h9.5H26.678z' />
  </svg>
)

export const TopBlobStyled = styled(TopBlob)`
  width: 98vw;
  z-index: -100;
  path {
    fill: whitesmoke;
  }
  width: 99vw;
  max-width: 1000px;

  ${media.xlarge`
    path {
      // fill: red;
    }
    width: 99vw;
  `}

  ${media.large`
    path {
      // fill: green;
    }
    width: 99vw;
  `}

  ${media.desktop`
    path {
      // fill: blue;
    }
    width: 99vw;
  `}
  ${media.tablet`
    path {
      // fill: violet;
    }
    width: 600px;
  `}

  ${media.phone`
    path {
      // fill: gold;
    }
  `}

  ${media.smallphone`
    path {
      // fill: deeppink;
    }
    width: 200vw;
    position: absolute;
    top: 0;
  `}
`
