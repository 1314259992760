import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { Header } from './Header'
import 'normalize.css'
// import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <div style={{ position: 'relative', overflowX: 'hidden', height: '100vh' }}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Full-stack JavaScript developer, striving to make every day better.' },
            { name: 'keywords', content: 'JavaScript, Developer, Full-Stack, Engineer, React, Node' },
            { name: 'author', content: 'Will Cook'}
          ]}
        >
          <html lang='en' />
        </Helmet>

        <Header />

        <div style={{ margin: '0 auto', maxWidth: 960, padding: '0px 1.0875rem', paddingTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {children}
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
