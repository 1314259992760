import React from 'react'
// import { Link } from 'gatsby' // for internal links
import styled from 'styled-components'
import { theme } from '../../theme'
import { TopBlobStyled } from '../Backgrounds/topBlob'
import { media } from '../../helpers/media'

import logo from '../../images/will-cook-logo-transparent.svg'

let AnimatedTopBlob = styled(TopBlobStyled)`
  /* Keyframes */
  // Pull the element down and enlarge
  @keyframes scaleUpDown {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.8);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: scaleUpDown linear 90s infinite;
`

const Wrapper = styled.div`
  margin-bottom: 1.45rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone`
    margin-bottom: 7rem;
  `}

  ${media.smallphone`
    margin-bottom: 18rem;
  `}
`

const Menu = styled.div`
  top: 75px;
  position: absolute;

  ${media.xlarge`
    top: 75px;
  `}
  ${media.large`
    top: 50px;
  `}
  ${media.desktop`
    top: 30px;
  `}
  ${media.tablet`
    top: 20px;
  `}
  ${media.phone`
    margin-left: 0;
  `}
  ${media.smallphone`
    margin-left: 0;
  `}
`

const Logo = styled('img')`
  width: 320px;
  margin-top: -45px;
  margin-bottom: -40px;

  ${media.smallphone`
    width: 300px;
  `}
`

const Navigation = styled('div')`
  text-align: center;
  margin-top: 8px;  

  a {
    font-family: ${theme.fonts.primary.family}, sans-serif;
    font-weight: ${theme.fonts.primary.weight[0]};
    text-decoration: none;
    color: ${theme.colors.darkGrey};
    margin: 10px;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  
  ${media.phone`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    text-align: center;
    justify-items: center;
    grid-row-gap: 1.2em;
  `}
`

let MobileList = (
  <Navigation>
    <a href='/#about'>About</a>
    <a href='/#portfolio'>Projects</a>
    <a href='/#contact'>Contact</a>
    <a href='/#links'>Links</a>
  </Navigation>
)

export class Header extends React.Component {
  render () {
    return (
      <Wrapper>
        <AnimatedTopBlob />

        <Menu>
          <Logo src={logo} alt='Will Cook logo' />
          {MobileList}
        </Menu>
      </Wrapper>
    )
  }
}
