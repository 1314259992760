import styled from 'styled-components'
import { theme } from '../theme'

export let StyledH1 = styled('h1')`
  margin: 0;
  color: ${theme.colors.darkGrey};
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`

export let StyledH2 = styled('h2')`
  color: ${theme.colors.darkGrey};
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  text-align: ${props => props.centered ? 'center' : 'left'};
`

export let StyledH3 = styled('h3')`
  color: ${theme.colors.darkGrey};
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`

export let StyledP = styled('p')`
  color: ${theme.colors.darkGrey};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`

export let StyledPLight = styled('p')`
  color: ${theme.colors.darkGrey};
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`

// A div dressed up as a button
export let ActionBtn = styled('div')`
  color: white;
  background-color: ${props => props.themeColor ? props.themeColor : 'lightgrey'};
  height: 34px;
  min-width: 80px;
  max-width: 130px;
  text-align: center;
  padding-top: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  &:hover {
    opacity: 0.8;
  }
`

export let LinkText = styled('a')`
  font-family: sans-serif;
  color: ${props => props.themeColor ? props.themeColor : 'darkgrey'};
  text-decoration: none;
  display: ${props => props.type ? props.type : 'block'};
`
